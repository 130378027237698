<template>
  <div class="px-40 border border-EEEEEE py-35 purseBox">
    <!-- 标签 -->
    <div class="flex align-center justify-between pb-20 border-bottom border-F4F4F4">
      <div class="font-20 text-333333" v-if="mytype == 1">粉丝</div>
      <div class="font-20 text-333333" v-if="mytype == 2">收到的赞</div>
      <div class="font-20 text-333333" v-if="mytype == 3">我的关注</div>
      <div class="font-12 text-666666 flex align-center">
        目前所在：<el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/myIndex' }">个人中心</el-breadcrumb-item>
          <el-breadcrumb-item v-if="mytype == 1">粉丝</el-breadcrumb-item>
          <el-breadcrumb-item v-if="mytype == 2">收到的赞</el-breadcrumb-item>
          <el-breadcrumb-item v-if="mytype == 3">我的关注</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <!-- 内容 -->
    <div>
      <!--内容  -->
      <div>
        <div class="flex py-20" v-for="(item, index) in info" :key="index">
          <!-- <img
            :src="item.avatar"
            width="3.8125rem"
            height="3.8125rem"
            class="circle mr-20"
            alt=""
          /> -->
          <el-image
            class="circle mr-20"
            style="width: 3.8125rem; height: 3.8125rem"
            :src="item.avatar"
            fit="cover"
          ></el-image>
          <div
            class="x-1 border-bottom border-F1F2F3 font-14 flex align-center justify-between"
          >
            <div>
              <div class="text-222222">
                {{ item.username }}
              </div>
              <div class="text-999999" v-if="mytype == 1">关注了你</div>
              <div class="text-999999" v-if="mytype == 2">赞了你的原创视频</div>
              <div class="text-999999" v-if="mytype == 3">我关注了Ta</div>
            </div>
            <div class="text-999999">
              {{ item.create_time }}
            </div>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <div class="flex align-center justify-center py-50 border-top border-ligit-BFBFBF">
        <el-pagination
          background
          layout="prev, pager, next"
          prev-text="上一页"
          next-text="下一页"
          :page-count="page_nums"
          @current-change="currentChange"
          :current-page="page"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import req from "../../../utils/req";
export default {
  data() {
    return {
      info: [],
      page: 1,
      page_nums: 0,
      mytype: "",
    };
  },

  mounted() {
    let mytype = this.$route.query.mytype;
    if (mytype) {
      this.mytype = mytype;
      this.getdata();
    }
  },
  watch: {
    // 被侦听的变量count
    $route: {
      handler() {
        let mytype = this.$route.query.mytype;
        if (mytype) {
          this.mytype = mytype;
          this.getdata();
        }
      },
      deep: true,
    },
  },
  methods: {
    currentChange(e) {
      console.log(e);
      this.page = e;
      this.getdata();
    },
    // 获取数据
    getdata() {
      let that = this;
      if (that.mytype == 1) {
        req
          .post("center/myFans", {})
          .then((res) => {
            if (res.code != 200) {
              that.$message.error(res.msg);
              return;
            }
            that.info = res.data.data;
            that.page_nums = res.data.last_page;
          })
          .catch((err) => {
            console.log(err);
            // that.$message.error(err.msg);
          });
      } else if (that.mytype == 2) {
        req
          .post("center/myFabulous", {})
          .then((res) => {
            if (res.code != 200) {
              that.$message.error(res.msg);
              return;
            }
            that.info = res.data.data;
            that.page_nums = res.data.last_page;
          })
          .catch((err) => {
            console.log(err);
            // that.$message.error(err.msg);
          });
      } else if (that.mytype == 3) {
        req
          .post("center/myFollow", {})
          .then((res) => {
            if (res.code != 200) {
              that.$message.error(res.msg);
              return;
            }
            that.info = res.data.data;
            that.page_nums = res.data.last_page;
          })
          .catch((err) => {
            console.log(err);
            // that.$message.error(err.msg);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.purseBox {
  height: 100%;
  box-sizing: border-box;
}
</style>
